import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { DefaultRes } from '../types';

export const ENVS = {
    dev: {
        BASE_URL: 'https://api.cnpjmei.org/v1',
    },
    stg: {
        BASE_URL: 'http://localhost:3000/v1',
    },
};

export const api = axios.create({
    baseURL: ENVS.dev.BASE_URL,
});

const onRequest = (axiosConfig: AxiosRequestConfig): AxiosRequestConfig => {
    return {
        ...axiosConfig,
    };
};
  
const onRequestError = (error: AxiosError): Promise<AxiosError> => Promise.reject(error);

const onResponseError = (error: AxiosError<DefaultRes>): Promise<AxiosError> => {
    return Promise.reject(error);
};

const onResponse = (response: AxiosResponse<DefaultRes>) => {
    return response;
};
// @ts-ignore
api.interceptors.request.use(onRequest, onRequestError);
// @ts-ignore
api.interceptors.response.use(onResponse, onResponseError);